import React, { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./styles.scss";

export const SignUpPopupEN = ({ setPopup, popup }) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [contactMethods, setContactMethods] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const multiSelectRef = useRef(null);
  const recaptchaRef = useRef(null);

  const contactMethodsOptions = ["Telegram", "Skype", "WhatsApp", "Email", "Phone"];

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleOptionChange = (option) => {
    if (contactMethods.includes(option)) {
      setContactMethods(contactMethods.filter((item) => item !== option));
    } else {
      setContactMethods([...contactMethods, option]);
    }
  };

  const getDisplayText = () => {
    return contactMethods.length > 0
      ? contactMethods.join(", ")
      : "Please choose the preferred method for communication";
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!popup) return;
      if (multiSelectRef.current && !multiSelectRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    if (popup) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popup]);

  const getParams = () => {
    const params = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }
    return paramsObject;
  };

  const sendFormData = async (formData) => {
    try {
      const url = process.env.REACT_APP_API_URL || 'https://register.inb.bio:5000/api';
      const response = await fetch(`${url}/user`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.message || "Network response was not ok");
      }
      console.log("Data sent successfully");
      return true;
    } catch (error) {
      setError(error?.message || "An unknown error occurred.");
      return false;
    }
  };

  const handleButtonClick = async () => {
    if (!contactInfo) {
      setError("Please fill in a valid phone, email, or username.");
      return;
    }
    if (contactMethods.length === 0) {
      setError("Please fill in at least one preferred method of communication.");
      return;
    }
    const captchaToken = await recaptchaRef?.current?.executeAsync();
    if (!captchaToken) {
      setError("Please complete the CAPTCHA.");
      return;
    }
    
    const success = await sendFormData({ 
      name,
      contactInfo,
      contactMethods,
      params: getParams(),
      captchaToken
    });
    if (!success) return;
    setName("");
    setContactInfo("");
    setContactMethods([]);
    setError("");
    setPopup(false);
    recaptchaRef.current.reset();
  };

  return (
    <div className={`sign-up-popup ${popup ? "active" : ""}`}>
      <div className="sign-up-popup__content">
        <div className="sign-up-popup__header">
          <h4>Sign Up</h4>
          <div className="sign-up-popup__close" onClick={() => setPopup(false)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 5L19 19M5.00003 19L12 12L19 5" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
          </div>
        </div>
        <form className="sign-up-popup__form" onSubmit={(e) => e.preventDefault()}>
        <input
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            type="text"
            name="contactInfo"
            placeholder="Phone/email/username*"
            value={contactInfo}
            required
            onChange={(e) => {
              setContactInfo(e.target.value);
              if (error) setError("");
            }}
          />
          <div className="sign-up-popup__multi-select" ref={multiSelectRef}>
            <div className={`select-input ${contactMethods.length === 0 ? "placeholder" : ""}`} onClick={toggleDropdown}>
              {getDisplayText()}
            </div>
            {isDropdownOpen && (
              <div className="dropdown">
                {contactMethodsOptions.map((option) => (
                  <label key={option} className="dropdown-option">
                    <input
                      type="checkbox"
                      checked={contactMethods.includes(option)}
                      onChange={() => handleOptionChange(option)}
                    />
                    <span>{option}</span>
                  </label>
                ))}
              </div>
            )}
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LcgGVoqAAAAAFnd_ArBw1HnULB3dqbyr_mwHxKQ'}
            size="invisible"
          />
          {error && <p className="error-message">{error}</p>}
          <p>By submitting the form, you agree to the privacy policy</p>
          <button type="button" onClick={handleButtonClick}>
            Send
          </button>
        </form>
      </div>
    </div>
  );
};
